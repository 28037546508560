/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ScopeEnum {
    REGISTERED = 'registered',
    CONTENT_MANAGER = 'content_manager',
    ADMIN = 'admin',
    CONTENT_CREATOR = 'content_creator',
    MODERATOR = 'moderator',
}
